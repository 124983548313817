import React from 'react'

const Feature = () => {
  return (
    <section className="feature" id="features">
			<div className="container">
             <header className="section-header">
          <h3 className="section-title">FEATURES</h3>
        </header>
			
				<div className="clearfix">
					<div className="featureCell">
						<figure>
							<img src={`${process.env.PUBLIC_URL}/images/art/circle1.png`} alt="circle" />
						</figure>
						<h6>Digital classroom</h6>
						<p>We want to reach out the learner students through our digital learning program that helps you visualize and understand your concepts better.</p>
					</div>
					<div className="featureCell active">
						<figure>
							<img src={`${process.env.PUBLIC_URL}/images/art/activeCircle2.png`} alt="activecircle" />
						</figure>
						<h6>E-Learning &amp; Animation</h6>
						<p>We offers value added solutions, services and products in the field of e-learning and 2d animation. We have best capabilities that meet your needs.</p>
					</div>
					<div className="featureCell">
						<figure>
							<img src={`${process.env.PUBLIC_URL}/images/art/circle3.png`} alt="circle" />
						</figure>
						<h6>Graphic Designing</h6>
						<p>We Create Design for Book publishing , magazine, Digital painting , Templates, Layouts, logo designing and web banner etc.</p>
					</div>
					<div className="featureCell">
						<figure>
							<img src={`${process.env.PUBLIC_URL}/images/art/circle4.png`} alt="circle" />
						</figure>
						<h6>web designing</h6>
						<p>We provide fully customized solutions to help your brand succeed with creative website designing and development.</p>
					</div>
				</div>
			</div>
		</section>
  )
}

export default Feature
