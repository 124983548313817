import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
const Banner = () => {
  return (
    <section className="banner">
      <div className="bannerWrapper">
        <Carousel infiniteLoop={true} showIndicators={false} showStatus={false}>
          <div className="bannerCell bannerCell1">
            <div className="container">
              <h3>Grow your knowledge with</h3>
              <strong>Art2anime Digital class room</strong>
              <p>
                We want to reach out the learner students through our
                interactive lessons, online test, learning games & quizzes.
              </p>
              <button type="button" className="reqDemo">
                REQUEST FOR DEMO
              </button>
            </div>
          </div>
          <div className="bannerCell bannerCell2">
            <div className="container">
              <h3>Get your digital solution with</h3>
              <strong>Art2Anime E-Learning Services</strong>
              <p>
                We offers value added solutions for all of your training needs
                through electronic media.
              </p>
              <button type="button" className="reqDemo">
                Learn More
              </button>
            </div>
          </div>
          <div className="bannerCell bannerCell3">
            <div className="container">
              <h3>Fulfill your video needs with </h3>
              <strong>Art2anime Animation Services</strong>
              <p>
                We have expertise in developing 2D educational videos to a
                full-fledged 2D Video Films.
              </p>
              <button type="button" className="reqDemo">
                Read More
              </button>
            </div>
          </div>
          <div className="bannerCell bannerCell4">
            <div className="container">
              <h3>Get creative Designs with</h3>
              <strong>Art2anime Graphic Services</strong>
              <p>
                We create design for book publishing, magazine, digital
                painting, templates, layouts, logo designing and web banner etc.
              </p>
              <button type="button" className="reqDemo">
                Read More
              </button>
            </div>
          </div>
          <div className="bannerCell bannerCell5">
            <div className="container">
              <h3>Build Your Website with</h3>
              <strong>Art2anime Web design Services</strong>
              <p>
                We provide fully customized solutions to help your brand succeed
                with creative website designing and development.
              </p>
              <button type="button" className="reqDemo">
                Read More
              </button>
            </div>
          </div>
        </Carousel>
      </div>
    </section>
  );
};

export default Banner;
