import React from 'react'

const Contact = () => {
  return (
    <section className="contactus" id="contactus">
    <div className="container clearfix">
        <div className="contactusLeft">
            <h4>CONTACT US</h4>
            <div className="contactWrap">
                <figure>
                    <img src={`${process.env.PUBLIC_URL}/images/art/contact.jpg`} alt="" />
                </figure>
                <div className="contactText">
                    <span><i><img src={`${process.env.PUBLIC_URL}/images/art/icon1.png`} alt="" /></i>Visit : WZ - 53, 1st Floor, Om
                        Vihar, Phase - 2,
                        Uttam Nagar West, New Delhi - 110059, India</span>
                    <span><i> <img src={`${process.env.PUBLIC_URL}/images/art/icon2.png`} alt="" /></i>91-9891751880,
                        9319900667</span>
                    <span><i> <img src={`${process.env.PUBLIC_URL}/images/art/icon3.png`} alt="" /></i>Mail :
                        info@art2anime.in</span>
                </div>
            </div>
        </div>
        <div className="contactForm" id="contactForm">
            <h4>request for product demo</h4>
            <form>
                <input type="text" placeholder="Your name*" />
                <input type="text" placeholder="Email*" />
                <textarea placeholder="Address*" style={{height:"83px"}}></textarea>
                <input type="text" placeholder="Phone*" />
                <textarea placeholder="message*" style={{height:"53px"}}></textarea>
                <button>Submit</button>
            </form>
        </div>
    </div>
</section>
  )
}

export default Contact
