import React from 'react';
import logo from './logo.svg';
import './App.css';
import Layout from './components/Layout';
import Banner from './components/Banner';
import Feature from './components/Feature';
import About from './components/About';
import Services from './components/Services';
import Classroom from './components/Classroom';
import Portfolio from './components/Portfolio';
import Video from './components/Video';
import Contact from './components/Contact';

function App() {
  return (
    <Layout>
      <Banner />
      <Feature />
      <About />
      <Services />
      <Classroom />
      <Portfolio />
      {/* <Video /> */}
      <Contact />
    </Layout>
  );
}

export default App;
