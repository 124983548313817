import React from 'react'

const About = () => {
  return (
    <section className="About" id="aboutus">
    <div className="container">
    <header className="section-header1">
  <h3 className="section-header">ABOUT</h3>
</header>
  

            <p className="bold">Art2Anime Media Group (A2A) based in New Delhi was established in 2012 as an IT and Education solution provider in India.
                The core areas of our company are K-12 Learning, Corporate Learning, Interactive E-Books, 2D Animation, Flash Gaming,
                Mobile Gaming, Graphic Designing, Website Designing with well trained and experienced IT Professionals.</p>
            <p>We are very sure that we will succeed to find our goal with your association. We are confident that you will be very
                satisfied with the services that we offer.</p>

            <div className="clearfix">
                <div className="picLeft">
                    <figure>
                        <img src={`${process.env.PUBLIC_URL}/images/art/about.png`} alt="about" />
                    </figure>
                </div>
                <div className="textRight">
                    <div className="textRightCell">
                        <h5>Our Vision</h5>
                        <p>To develop a learning environment supported by continuously evolving, collaborative processes focused on increasing
                            individual and organizational performance. </p>
                    </div>
                    <div className="textRightCell">
                        <h5>Our Mission</h5>
                        <p>To reach out to learner students of all ages, from beginners to professionals through:-</p>
                        <ul>
                            <li>Products – those are technically superior</li>
                            <li>Services – that are worldwide best </li>
                            <li>Solutions – those are easily accessible</li>
                        </ul>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default About
