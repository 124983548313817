import React from "react";

const Classroom = () => {
  return (
    <section id="classroom" className="classroom">
      <div className="container">
        <header className="section-header1">
          <h3 className="section-title">Classroom</h3>
        </header>

        <p>
          <strong>
            Our Digital Classroom creates Virtual Learning Environment for
            learner students, from the elementary Kindergarten to className 12.
            Our Learning Management System includes Interactive lesson,
            Assessment, learning games & quizzes, Online Test and Generate
            Student Progress Report through our Web Portal.
          </strong>
        </p>

        <p>We have best capabilities that meet the needs of our user. </p>

        <div className="row container" id="portfolio1">
          <div className="col-lg-4 col-md-6 portfolio-item wow fadeInUp">
            <div className="portfolio-wrap">
              <figure>
                <img
                  src={`${process.env.PUBLIC_URL}/extra_css/img/portfolio/class1.jpg`}
                  className="img-fluid"
                  alt=""
                />
                <a
                  href={`${process.env.PUBLIC_URL}/extra_css/img/portfolio/class1.jpg`}
                  data-lightbox="portfolio"
                  data-title="KINDERGARTEN"
                ></a>
              </figure>

              <div className="portfolio-info">
                <h4>KINDERGARTEN</h4>
                <h6>
                  <a href="user_login">JOIN US</a>
                </h6>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item wow fadeInUp">
            <div className="portfolio-wrap">
              <figure>
                <img
                  src={`${process.env.PUBLIC_URL}/extra_css/img/portfolio/class2.jpg`}
                  className="img-fluid"
                  alt=""
                />
                <a
                  href={`${process.env.PUBLIC_URL}/extra_css/img/portfolio/class2.jpg`}
                  data-lightbox="portfolio"
                  data-title="GRADE-1"
                ></a>
              </figure>

              <div className="portfolio-info">
                <h4>GRADE-1</h4>
                <h6>
                  <a href="user_login">JOIN US</a>
                </h6>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item wow fadeInUp">
            <div className="portfolio-wrap">
              <figure>
                <img
                  src={`${process.env.PUBLIC_URL}/extra_css/img/portfolio/class3.jpg`}
                  className="img-fluid"
                  alt=""
                />
                <a
                  href={`${process.env.PUBLIC_URL}/extra_css/img/portfolio/class3.jpg`}
                  data-lightbox="portfolio"
                  data-title="GRADE-2"
                ></a>
              </figure>

              <div className="portfolio-info">
                <h4>GRADE-2</h4>
                <h6>
                  <a href="user_login">JOIN US</a>
                </h6>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item wow fadeInUp">
            <div className="portfolio-wrap">
              <figure>
                <img
                  src={`${process.env.PUBLIC_URL}/extra_css/img/portfolio/class4.jpg`}
                  className="img-fluid"
                  alt=""
                />
                <a
                  href={`${process.env.PUBLIC_URL}/extra_css/img/portfolio/class4.jpg`}
                  data-lightbox="portfolio"
                  data-title="GRADE-3"
                ></a>
              </figure>

              <div className="portfolio-info">
                <h4>GRADE-3</h4>
                <h6>
                  <a href="user_login">JOIN US</a>
                </h6>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item wow fadeInUp">
            <div className="portfolio-wrap">
              <figure>
                <img
                  src={`${process.env.PUBLIC_URL}/extra_css/img/portfolio/class5.jpg`}
                  className="img-fluid"
                  alt=""
                />
                <a
                  href={`${process.env.PUBLIC_URL}/extra_css/img/portfolio/class5.jpg`}
                  data-lightbox="portfolio"
                  data-title="GRADE-4"
                ></a>
              </figure>

              <div className="portfolio-info">
                <h4>GRADE-4</h4>
                <h6>
                  <a href="user_login">JOIN US</a>
                </h6>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item wow fadeInUp">
            <div className="portfolio-wrap">
              <figure>
                <img
                  src={`${process.env.PUBLIC_URL}/extra_css/img/portfolio/class6.jpg`}
                  className="img-fluid"
                  alt=""
                />
                <a
                  href={`${process.env.PUBLIC_URL}/extra_css/img/portfolio/class6.jpg`}
                  data-lightbox="portfolio"
                  data-title="GRADE-5"
                ></a>
              </figure>

              <div className="portfolio-info">
                <h4>GRADE-5</h4>
                <h6>
                  <a href="user_login">JOIN US</a>
                </h6>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item wow fadeInUp">
            <div className="portfolio-wrap">
              <figure>
                <img
                  src={`${process.env.PUBLIC_URL}/extra_css/img/portfolio/class7.jpg`}
                  className="img-fluid"
                  alt=""
                />
                <a
                  href={`${process.env.PUBLIC_URL}/extra_css/img/portfolio/class7.jpg`}
                  data-lightbox="portfolio"
                  data-title="GRADE-6"
                ></a>
              </figure>

              <div className="portfolio-info">
                <h4>GRADE-6</h4>
                <h6>
                  <a href="user_login">JOIN US</a>
                </h6>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item wow fadeInUp">
            <div className="portfolio-wrap">
              <figure>
                <img
                  src={`${process.env.PUBLIC_URL}/extra_css/img/portfolio/class8.jpg`}
                  className="img-fluid"
                  alt=""
                />
                <a
                  href={`${process.env.PUBLIC_URL}/extra_css/img/portfolio/class8.jpg`}
                  data-lightbox="portfolio"
                  data-title="GRADE-7"
                ></a>
              </figure>

              <div className="portfolio-info">
                <h4>GRADE-7</h4>
                <h6>
                  <a href="user_login">JOIN US</a>
                </h6>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item wow fadeInUp">
            <div className="portfolio-wrap">
              <figure>
                <img
                  src={`${process.env.PUBLIC_URL}/extra_css/img/portfolio/class9.jpg`}
                  className="img-fluid"
                  alt=""
                />
                <a
                  href={`${process.env.PUBLIC_URL}/extra_css/img/portfolio/class9.jpg`}
                  data-lightbox="portfolio"
                  data-title="GRADE-8"
                ></a>
              </figure>

              <div className="portfolio-info">
                <h4>GRADE-8</h4>
                <h6>
                  <a href="user_login">JOIN US</a>
                </h6>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item wow fadeInUp">
            <div className="portfolio-wrap">
              <figure>
                <img
                  src={`${process.env.PUBLIC_URL}/extra_css/img/portfolio/class10.jpg`}
                  className="img-fluid"
                  alt=""
                />
                <a
                  href={`${process.env.PUBLIC_URL}/extra_css/img/portfolio/class10.jpg`}
                  data-lightbox="portfolio"
                  data-title="GRADE-9"
                ></a>
              </figure>

              <div className="portfolio-info">
                <h4>GRADE-9</h4>
                <h6>
                  <a href="user_login">JOIN US</a>
                </h6>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item wow fadeInUp">
            <div className="portfolio-wrap">
              <figure>
                <img
                  src={`${process.env.PUBLIC_URL}/extra_css/img/portfolio/class11.jpg`}
                  className="img-fluid"
                  alt=""
                />
                <a
                  href={`${process.env.PUBLIC_URL}/extra_css/img/portfolio/class11.jpg`}
                  data-lightbox="portfolio"
                  data-title="GRADE-10"
                ></a>
              </figure>

              <div className="portfolio-info">
                <h4>GRADE-10</h4>
                <h6>
                  <a href="user_login">JOIN US</a>
                </h6>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item wow fadeInUp">
            <div className="portfolio-wrap">
              <figure>
                <img
                  src={`${process.env.PUBLIC_URL}/extra_css/img/portfolio/class12.jpg`}
                  className="img-fluid"
                  alt=""
                />
                <a
                  href={`${process.env.PUBLIC_URL}/extra_css/img/portfolio/class12.jpg`}
                  data-lightbox="portfolio"
                  data-title="GRADE-11 & 12"
                ></a>
              </figure>

              <div className="portfolio-info">
                <h4>GRADE-11 & 12</h4>
                <h6>
                  <a href="user_login">JOIN US</a>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Classroom;
