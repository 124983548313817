import React from "react";
import { Link } from "react-scroll";
const Header = () => {
	const offset = -190;
  return (
    <header id="header">
      <div className="upperheader">
        <div className="container clearfix">
          <a href="tel:+91-9891751880" className="iconLeft">
            <img src={`${process.env.PUBLIC_URL}/images/art/one.png`} alt="" />
            +91-9891751880
          </a>
          <a href="mailto:info@art2anime.in" className="iconLeft">
            <img src={`${process.env.PUBLIC_URL}/images/art/two.png`} alt="" />
            info@art2anime.in
          </a>
          <a href="#" className="iconRight">
            <img
              src={`${process.env.PUBLIC_URL}/images/art/three.png`}
              alt=""
            />
            Login/Register
          </a>
          <a href="#contactForm" className="iconRight">
            Request for Demo
          </a>
        </div>
      </div>
      <div className="lowerheader">
        <div className="container clearfix">
          <a href="/" className="logo">
            <img src={`${process.env.PUBLIC_URL}/images/CaratLearn_Final Logo_1.png`} alt="" />
          </a>
          <nav className="nav-menu">
            <Link
              activeClass="menu-active"
              href="#"
              to="features"
              spy={true}
              smooth={true}
              offset={offset}
            >
              Features
            </Link>
            <Link
              activeClass="menu-active"
              href="#"
              to="aboutus"
              spy={true}
              smooth={true}
              offset={offset}
            >
              About Us
            </Link>
            <Link
              activeClass="menu-active"
              href="#"
              to="services"
              spy={true}
              smooth={true}
              offset={offset}
            >
              Services
            </Link>
            <Link
              activeClass="menu-active"
              href="#"
              to="classroom"
              spy={true}
              smooth={true}
              offset={offset}
            >
              ClassRoom
            </Link>
            <Link
              activeClass="menu-active"
              href="#"
              to="portfolio"
              spy={true}
              smooth={true}
              offset={offset}
            >
              Portfolio
            </Link>
            <Link
              activeClass="menu-active"
              href="#"
              to="contactus"
              spy={true}
              smooth={true}
              offset={offset}
            >
              Contact Us
            </Link>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
