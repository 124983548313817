import React from "react";
import { LayoutProps } from "../interface/ComponentsInterface";
import Footer from "./Footer";
import Header from "./Header";

const Layout = ({ children }: LayoutProps) => {
  return (
    <div id="wrapper">
      <Header />
      <div id="content" style={{ background: "#fff" }}>
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
