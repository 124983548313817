import React, { useRef, useState } from "react";
const portfolioData = [
  {
    image: "extra_css/img/portfolio/c_01.jpg",
    video: "extra_css/img/portfolio/c_01.mp4",
    category: ["classroom"],
  },
  {
    image: "extra_css/img/portfolio/c_02.jpg",
    video: "",
    category: ["classroom"],
  },
  {
    image: "extra_css/img/portfolio/c_03.jpg",
    video: "",
    category: ["classroom"],
  },
  {
    image: "extra_css/img/portfolio/el_01.jpg",
    video: "",
    category: ["eLearn"],
  },
  {
    image: "extra_css/img/portfolio/el_02.jpg",
    video: "",
    category: ["eLearn"],
  },
  {
    image: "extra_css/img/portfolio/el_03.jpg",
    video: "",
    category: ["eLearn"],
  },
  {
    image: "extra_css/img/portfolio/el_04.jpg",
    video: "",
    category: ["eLearn"],
  },
  {
    image: "extra_css/img/portfolio/el_05.jpg",
    video: "",
    category: ["eLearn"],
  },
  {
    image: "extra_css/img/portfolio/el_06.jpg",
    video: "",
    category: ["eLearn"],
  },
  {
    image: "extra_css/img/portfolio/g_01.jpg",
    video: "",
    category: ["Games"],
  },
  {
    image: "extra_css/img/portfolio/g_02.jpg",
    video: "",
    category: ["Games"],
  },
  {
    image: "extra_css/img/portfolio/g_03.jpg",
    video: "",
    category: ["Games"],
  },
  {
    image: "extra_css/img/portfolio/gr_01.jpg",
    video: "",
    category: ["gDesign"],
  },
  {
    image: "extra_css/img/portfolio/gr_02.jpg",
    video: "",
    category: ["gDesign"],
  },
  {
    image: "extra_css/img/portfolio/gr_03.jpg",
    video: "",
    category: ["gDesign"],
  },
];
const filters = [
  {
    name: "All",
    filter: "*",
  },
  {
    name: "ClassRoom",
    filter: "classroom",
  },
  {
    name: "E-Learning",
    filter: "eLearn",
  },
  {
    name: "Learning Games",
    filter: "Games",
  },
  {
    name: "Graphic & Web Design",
    filter: "gDesign",
  },
];
const Portfolio = () => {
  const [filter, setFilter] = useState<string>("*");

  return (
    <section id="portfolio" className="section-bg">
      <div className="container">
        <header className="section-header">
          <h3 className="section-title">Our Portfolio</h3>
        </header>

        <div className="row">
          <div className="col-lg-12">
            <ul id="portfolio-flters">
              {filters.map((f, ind) => (
                <li
                  onClick={() => setFilter(f.filter)}
                  className={filter === f.filter ? "filter-active" : ""}
                  key={ind}
                >
                  {f.name}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="container">
          <div className="row portfolio-container">
            {portfolioData.map((portfolio, ind) => {
              if (portfolio.category.includes(filter) || filter === "*") {
                return (
                  <div
                    className="col-lg-4 col-md-6 portfolio-item wow fadeInUp"
                    key={ind}
                  >
                    <div className="portfolio-wrap">
                      <figure>
                        <img
                          src={`${process.env.PUBLIC_URL}/${portfolio.image}`}
                          className="img-fluid"
                          alt=""
                        />
                        <a
                          href={`${process.env.PUBLIC_URL}/${
                            portfolio.video || portfolio.image
                          }`}
                          data-lightbox="portfolio"
                          className="link-preview"
                        >
                          <i className="ion ion-eye"></i>
                        </a>
                        <a
                          href="#"
                          className="link-details"
                          title="More Details"
                        >
                          <i className="ion ion-android-open"></i>
                        </a>{" "}
                      </figure>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
