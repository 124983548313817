import React from 'react'

const Services = () => {
  return (
    <section className="services" id="services">
			<div className="container">
            <header className="section-header">
          <h3 className="section-title">SERVICES</h3>
        </header>
				
					<p><strong>Art2Anime Media Group consists of team of experts in E-learning, Learning Game, Graphic designing, Web Designing and providing technology support for course development as per client requirement.</strong>
					</p>
					<p className = "big">
						Our core areas of services are:
					</p>
					<ul className="serviceList clearfix">
						<li>* K-12 Learning</li>
						<li>* Corporate Learning</li>
						<li>* Interactive E-Books</li>
						<li>* 2D Animation</li>
						<li>* Flash Gaming</li>
						<li>* Mobile Gamimg</li>
						<li>* Graphic Designing</li>
						<li>* Web Desiging</li>
					</ul>
                                 
					<div className="clearfix serviceSection">
                     <img src={`${process.env.PUBLIC_URL}/images/art/Divider.png`} alt="" />  
						<div className="servicePic">
							<figure>
								<img src={`${process.env.PUBLIC_URL}/images/art/servicePic_01.png`} alt="" />
							</figure>
						</div>
						<div className="serviceText">
							<h3>E-learning:</h3>
							<p>Art2Anime Media Group offers value added solutions, services and products in the e-learning field that serves the interest of Educational Publishers, Universities, Schools, Government as well as Corporate sector.</p>
							<p>Our E-learning applications include Virtual Learning Environment, Learning Management System, Assessment Managing System, Web Portal, Online Examination System, Knowledge Management System, and Content Packaging Tools. We have best capabilities that meet the needs of teachers and learners.</p>
							<p>Our expertise and experience span the entire range of digital learning solutions, from the elementary K-12 to the highly technical and structured undergraduate and graduate curricula, to the skill-oriented corporate learning solutions.</p>
                            <p>Our services cover content development, 2-D, subject matter expertise and the testing of E-learning courseware.</p>
						
                        </div>
					</div>
                    <div className="clearfix serviceSection">
                     <img src={`${process.env.PUBLIC_URL}/images/art/Divider.png`} alt="" />  
						<div className="servicePic">
							<figure>
								<img src={`${process.env.PUBLIC_URL}/images/art/servicePic_02.png`} alt="" />
							</figure>
						</div>
						<div className="serviceText">
							<h3>2D Animation:</h3>
							<p>We have a team of highly experienced & skillful animators, illustrators and designers, to produce variety of animated work from 2D educational videos to a full-fledged 2D Video Films.</p>
							<p>We create animation for a wide range of applications such as entertainment, short stories, TV commercials, educational presentations, product demos, etc.</p>
							<p>Our creative team produce unique and exciting theme of animations that keep children and elders interested.</p>
                            
                           <p>
						2D Animation Services We Offer:
					</p>
                            
                        <ul className="serviceList2 clearfix">
						<li>* Game Animation</li>
						<li>* Cartoon Animation</li>
						<li>* Explainer Videos</li>
						<li>* Motion Graphics</li>
						<li>* Whiteboard Animation</li>
						<li>* Typography Animation</li>
					</ul>
                            
                           						
                        </div>
					</div>
                    <img src={`${process.env.PUBLIC_URL}/images/art/Divider.png`} alt="" />  
					<div className="clearfix serviceSection">
						<div className="servicePic">
							<figure>
								<img src={`${process.env.PUBLIC_URL}/images/art/servicePic_03.png`} alt="" />
							</figure>
						</div>
						<div className="serviceText">
							<h3>Learning Games:</h3>
							<p>Presently we have seen that learning is an another way that has found singnificance in the gaming world.</p>
							<p>Learning games include several aspects such as a visualize environment and fun factors that give the users an interesting way of competing while learning.</p>
							<p>Art2Anime develops Creative and Funny educational games that can be easily controlled by users. We are dedicated to providing our uses the best Learning gaming experience on the multi-platform devices such as Mobile and Tab.</p>
						</div>
					</div>
                     <img src={`${process.env.PUBLIC_URL}/images/art/Divider.png`} alt="" />  
					<div className="clearfix serviceSection">
						<div className="servicePic">
							<figure>
								<img src={`${process.env.PUBLIC_URL}/images/art/servicePic_04.png`} alt="" />
							</figure>
						</div>
						<div className="serviceText">
							<h3>Graphic Designing:</h3>
							<p>Art2Anime Create Design for Book publishing , magazine, Digital painting , Templates, Layouts, logo designing and web banner etc.</p>
							<p>Our creative instincts, breadth of graphic designing capabilities, depth of expertise and sufficient resources allow our clients to serve their market with great accuracy and amazing finesse.</p>
							<p>The Computer Aided Design (CAD) industry works hard to give you the best digital and print outputs. We are at the core of this industry. Thus, we are a One Stop Shop for all your Graphics Design, Documentation and Print services. We believe in building relationships with our valued clients.</p>
						</div>
					</div>
                     <img src={`${process.env.PUBLIC_URL}/images/art/Divider.png`} alt="" />  
					<div className="clearfix serviceSection">
						<div className="servicePic">
							<figure>
								<img src={`${process.env.PUBLIC_URL}/images/art/servicePic_05.png`} alt="" />
							</figure>
						</div>
						<div className="serviceText">
							<h3>Web Designing & Development:</h3>
							<p>Art2Anime Media Group aims to be the leading global provider of Web designing services. We provide fully customized solutions to help your brand succeed with creative website designing & development.</p>
							<p>We create always good relationship with our customer with our creative website designing service.</p>
						</div>
					</div>
				</div>
			</section>
  )
}

export default Services
