import React from 'react'

const Footer = () => {
  return (
    <footer id="footer">
		<div className="container">
			<a href="#">Term &amp; Conditions</a>
			<a href="#">Privacy Policy</a>
			<span className="followUs">Follow Us
				<a href="#">
					<img src={`${process.env.PUBLIC_URL}/images/art/facebook.png`} alt="facebook" />
				</a>
				<a href="#">
					<img src={`${process.env.PUBLIC_URL}/images/art/twitter.png`} alt="twitter" />
				</a>
				<a href="#">
					<img src={`${process.env.PUBLIC_URL}/images/art/linkedin.png`} alt="linkedin"/>
				</a>
			</span>
			<span>&copy;2019Art2anime Media Group</span>
		</div>

	</footer>
  )
}

export default Footer
